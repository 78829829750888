import React from "react";
import ReactDOM from "react-dom";

// Polyfills
import "react-app-polyfill/ie11";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "core-js/features/array/includes";
import "core-js/features/object/values";
import "./lib/Polyfills";

import "./index.css";
import "./widget-styles.css";
import MainApp from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<MainApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
